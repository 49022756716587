<template>
  <basic-container>
    <avue-crud :option="option"
               :data="data"
               :page.sync="page"
               ref="crud"
               @row-del="rowDel"
               v-model="form"
               :permission="permissionList"
               @row-update="rowUpdate"
               @row-save="rowSave"
               :before-open="beforeOpen"
               @search-change="searchChange"
               @search-reset="searchReset"
               @refresh-change="refreshChange"
               @selection-change="selectionChange"
               @on-load="onLoad">
      <template slot="menuLeft">
        <!-- <el-button type="danger"
                   size="small"
                   icon="el-icon-delete"
                   plain
                   v-if="permission.user_wxuserdetail_delete"
                   @click="handleDelete">删 除
        </el-button> -->
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { getList, getUser, getDetail, submit, remove } from '@/api/user/healthManagementSpecialist'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      form: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      wxUserId: '',
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        delBtn: false,
        column: [
          {
            type: 'tree',
            label: '选择成员',
            prop: 'ssProvince',
            span: 24,
            hide: true,
            addDisplay: true,
            editDisabled: false,
            // dicUrl: 'https://dbadmin.dbzdjk.com/admin/fy/rest/third/getUserInfo',
            dicUrl: 'https://dbadmin.dbzdjk.com/admin/fy/rest/third/getUserDoctor',
            // dicUrl: 'http://localhost:6126/admin/fy/rest/third/getUserDoctor',
            // dicUrl: 'http://localhost:6126/admin/fy/rest/third/getUserInfo',
            dicMethod: 'post',
            props: {
              label: 'name',
              value: 'code'
            },
            slot: true,
            nodeClick: (data) => {
              getUser(data.code).then(res => {
                console.log('csssssss')
                console.log(res)
                const data1 = res.data
                this.form = data1
                this.wxUserId = data1.wxUserId
              })
            }
          },
          {
            label: '名称',
            prop: 'userName',
            rules: [{
              required: true,
              message: '请输入用户名称'
            }]
          },
          {
            label: '电话',
            prop: 'phone',
            rules: [{
              required: true,
              message: '请输入用户手机号'
            }]
          },
          {
            label: '管理师身份',
            prop: 'healthManagementSpecialist',
            type: 'select',
            dicData: [
              { label: '不是', value: 0 },
              { label: '是', value: 1 }
            ]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            cell: false,
            addDisplay: false,
            editDisabled: true
          },
          {
            label: '修改时间',
            prop: 'updateTime',
            cell: false,
            addDisplay: false,
            editDisabled: true
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.user_wxuserdetail_add,
        viewBtn: this.permission.user_wxuserdetail_view,
        delBtn: this.permission.user_wxuserdetail_delete,
        editBtn: this.permission.user_wxuserdetail_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }
  },
  methods: {
    rowSave (row, done, loading) {
      row.wxUserId = this.wxUserId
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },
    searchReset () {
      this.onLoad(this.page)
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.onLoad(this.page, data.searchForm)
    },
    // handleDelete () {
    //   if (this.selectionList.length === 0) {
    //     this.$message.warning('请选择至少一条数据')
    //     return
    //   }
    //   this.$confirm('确定将选择数据删除?', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   })
    //     .then(() => {
    //       return remove(this.ids)
    //     })
    //     .then(() => {
    //       this.onLoad(this.page)
    //       this.$message({
    //         type: 'success',
    //         message: '操作成功!'
    //       })
    //       this.$refs.crud.toggleSelection()
    //     })
    // },

    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },
    onLoad (page, params = {}) {
      getList(page.currentPage, page.pageSize, params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    }
  }
}
</script>
