<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page.sync="page"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot-scope="scope" slot="menu">
        <el-button type="text" size="small"
                   @click.stop="handleDetail(scope.row,scope.index)">评估报告
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { getBreathDataList, getDetail, submit, remove } from '@/api/wx/wxuser'
import { mapGetters } from 'vuex'
import func from '@/util/func'

export default {
  data () {
    return {
      form: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      params: {
        meterDate: func.format(new Date(), 'YYYY-mm-dd'),
        bpReportStatus: 0
      },
      selectionList: [],
      option: {
        searchMenuSpan: 12,
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        selection: false,
        align: 'center',
        column: [
          {
            label: '测量时间',
            prop: 'meterDate',
            type: 'week',
            format: 'yyyy 第 WW 周',
            valueFormat: 'yyyy-MM-dd',
            hide: true,
            search: true,
            searchValue: func.format(new Date(), 'YYYY-mm-dd'),
            change: ({ value }) => {
              this.params.meterDate = value
              if (this.params.meterDate === undefined) {
                return
              }
              this.onLoad(this.page)
            }
          },
          {
            label: '姓名',
            prop: 'jkName',
            detail: true,
            search: true
          },
          {
            label: '年龄',
            prop: 'jkAge',
            detail: true
          },
          {
            label: '性别',
            prop: 'jkSex',
            type: 'select',
            dicUrl: '/sys/dict/code/sex',
            detail: true
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.permission.wx_user_add,
        viewBtn: this.permission.wx_user_view,
        delBtn: this.permission.wx_user_delete,
        editBtn: this.permission.wx_user_edit
      }
    },
    ids () {
      const ids = []
      this.selectionList.forEach(ele => {
        ids.push(ele.id)
      })
      return ids.join(',')
    }

  },

  methods: {
    getRowKey (row) {
      console.log(row.id, '====================')
      return row.id
    },
    handleDetail (data) {
      this.$router.push({
        path: '/health/breathmeterdata_detail',
        query: { wxUserId: data.id, meterDate: this.params.meterDate }
      })
    },
    rowSave (row, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      submit(row).then(() => {
        done()
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        })
    },

    searchChange (params, done) {
      this.params = params
      this.onLoad(this.page)
      done()
    },
    selectionChange (list) {
      this.selectionList = list
    },
    refreshChange (data) {
      this.params = data.searchForm
      this.onLoad(this.page)
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          return remove(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$refs.crud.toggleSelection()
        })
    },

    beforeOpen (done, type) {
      if (['edit', 'view'].includes(type)) {
        getDetail(this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },

    onLoad (page) {
      console.log('test')
      this.params.type = 1
      this.params.channel = window.localStorage.getItem('channel')
      getBreathDataList(page.currentPage, page.pageSize, this.params).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    }
  }
}
</script>

<style>
</style>
